<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Technician Payments/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo bx-flashing'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>Start Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedFromDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>End Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedToDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :max-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>Technician</label>
                  <VueMultiselect v-model="selectedTechnician" class="" :options="technicianOptions"
                                  :close-on-select="true"
                                  placeholder="Select status" label="name" track-by="name" :show-labels="false"
                                  :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        :disabled="isSearchButtonDisable"
                        @click="getTechnicianAppointmentList">Search
                </button>
              </div>
            </div>
            <div class="row pt-2">

            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div v-if="this.technician.id" class="card">
            <div class="card-body row">
              <div class=" col-md-4 col-sm-12">
                <span style="font-weight: bold">Technician Name: </span>
                <span>{{ this.technician.user?.full_name }}</span>
              </div>
              <div class=" col-md-4 col-sm-12">
                <span style="font-weight: bold">Technician Email: </span>
                <span>{{ this.technician.user?.email }}</span>
              </div>
              <div class=" col-md-4 col-sm-12">
                <span style="font-weight: bold">Technician Phone Number: </span>
                <span>{{ this.technician.user?.phone_number }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Employee Type: </span>
                <span>{{ this.technician?.employee?.type }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Employee ID: </span>
                <span>{{ this.technician?.employee?.id_number }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Employee Designation: </span>
                <span>{{ this.technician?.employee?.designation }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Employee Salary: </span>
                <span>{{ `${(this.technician?.employee?.salary_amount/100)?.toFixed(2) ?? 0}${this.technician?.employee?.salary_type === 'Hourly' ? '%' : ' AUD'} ` }}
                  ({{ this.technician?.employee?.salary_type }})
                </span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Order Product Commission: </span>
                <span>{{ this.technician?.product_commission_rate }}% ({{ this.technician?.product_commission_type }})</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Date Range: </span>
                <span>{{ this.dateFormatter(this.selectedFromDate) }} - {{ this.dateFormatter(this.selectedToDate) }}</span>
              </div>
              <hr>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Service Commission: </span>
                <span>${{ (this.totalTechnicianWageAmountFromAppointment / 100)?.toFixed(2) ?? 0 }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Order Product Commission: </span>
                <span>${{ (this.totalOrderProductCommissionAmount / 100)?.toFixed(2) ?? 0 }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Payable Commission: </span>
                <span>${{ (this.totalTechnicianCommissionPayableAmount / 100)?.toFixed(2) ?? 0 }}</span>
              </div>

            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div v-if="technicianAppointmentListPayments.length" class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'25%'}">JOB DETAILS</th>
                    <th class="position-relative text-right" :style="{width:'15%'}">SERVICE PRICE</th>
                    <th class="position-relative" :style="{width:'10%'}">HOURS</th>
                    <th class="position-relative text-right" :style="{width:'15%'}">SERVICE COMMISSION</th>
                    <th class="position-relative" :style="{width:'10%'}">ORDER</th>
                    <th class="position-relative text-right" :style="{width:'10%'}">PRODUCT COMMISSION</th>
                    <th class="position-relative text-right" :style="{width:'10%'}">JOB VAlUE</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(technicianAppointmentDetails, index) in this.technicianAppointmentListPayments" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div>
                        {{ technicianAppointmentDetails?.reference }}
                        ({{ technicianAppointmentDetails?.customer?.userFullName }})
                      </div>
                      <div>
                        <span class="font-size-small">
                          Service: {{ technicianAppointmentDetails.serviceName }}
                        </span>
                      </div>
                      <div>
                        <span class="font-size-small">
                          {{ technicianAppointmentDetails.date }} at {{ technicianAppointmentDetails.time }}
                        </span>
                      </div>
                    </td>
                    <td class="text-right">
                      <span>${{ (technicianAppointmentDetails.unitPrice / 100).toFixed(2) }}</span>
                    </td>
                    <td>{{ technicianAppointmentDetails.length }}</td>
                    <td class="text-right">
                      ${{ (technicianAppointmentDetails.technicianWageAmountFromAppointment / 100).toFixed(2) }}
                    </td>
                    <td>
                      <span class="font-size-small">
                        {{ technicianAppointmentDetails.taggedOrder?.orderNo ?? "-" }}
                      </span><br/>
                      <span class="font-size-small">
                        {{ technicianAppointmentDetails.taggedOrder?.orderNo ? 'Status: ' : '' }}
                        {{ technicianAppointmentDetails.taggedOrder?.status }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span>
                        ${{ (technicianAppointmentDetails.orderProductCommissionAmount / 100).toFixed(2) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span>
                        ${{ (technicianAppointmentDetails.totalJobValue / 100).toFixed(2) }}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="from-group text-right">
                  <button type="button" class="btn btn-sm btn-primary" @click="generatePaymentButtonHandler">Generate Payment</button>
                </div>
              </div>
              <div v-else>
                <span class="text-center text-info">{{ this.noTechnicianAppointmentListMessage }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import {mapActions, mapGetters} from "vuex";

import VueMultiselect from "vue-multiselect";
import {DatePicker} from "v-calendar";

import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "TechnicianPaymentCreate",
  components: {
    AppLayout,
    DatePicker,
    VueMultiselect,
  },
  mixins: [Loader, ShowToastMessage, Authorization],
  data() {
    return {
      selectedFromDate: null,
      selectedToDate: null,
      selectedTechnician: {
        value: '',
        name: 'None'
      },

      getTechnicianParams: {
        with_relation: [
          'employee',
          'user',
        ],
      },

      getTechniciansParams: {
        status: 1,
        employee_type: [2, 3], // 2:Contractual, 3:Partner
        where_doesnt_have_franchisee_technician: 1,
        with_relation: ['user']
      },

      getTechnicianAppointmentsParams: {
        technician_id: null,
        where_has_appointment_status: [2, 4], // closed, delivered
        where_has_appointment_date: [null, null],
        order_by_appointment_date: 'ASC',
        order_by_appointment_time: 'ASC',


        with_relation: [
          'appointment.order.orderProductItems.productItemTransactions',
          'appointment.order.orderProductItems.productItem.product',
          'appointment.appointmentCharges',
          'appointment.service',
          'appointment.customer.user',
        ],
      },
      selectedTechnicianDetails: {},
      technicianAppointmentListPayments: [],
      totalTechnicianWageAmountFromAppointment: 0,
      totalOrderProductCommissionAmount: 0,
      totalTechnicianCommissionPayableAmount: 0,
      totalServiceHour: 0,
      noTechnicianAppointmentListMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      technician: 'appTechnicians/technician',
      technicianAppointments: 'appTechnicianAppointments/technicianAppointments',
    }),
    redirectRouteName() {
      return this.can('technician-commission-payment-view-any')
          ? 'appTechnicianCommissionPaymentList'
          : 'appTechnicianCommissionPaymentCreate';
    },
    technicianOptions() {
      let activeTechnicians = this.$store.getters['appTechnicians/technicians'].map((technician) => {
        let technicianId = technician.id;
        let fullName = technician?.user?.full_name ?? '';

        return {
          value: technicianId,
          name: fullName
        };
      });

      return [{
        value: '',
        name: `None`
      }, ...activeTechnicians];
    },
    isSearchButtonDisable() {
      return !(this.selectedFromDate !== '' && this.selectedToDate !== '' && this.selectedTechnician.value !== '');
    },
    salaryTypeOptions() {
      return [
        {
          value: 0,
          name: 'Fixed'
        },
        {
          value: 1,
          name: 'Hourly'
        }
      ]
    },
    commissionTypeOptions() {
      return [
        {
          value: 0,
          name: 'None'
        },
        {
          value: 1,
          name: 'Hardware'
        },
        {
          value: 2,
          name: 'Software'
        },
        {
          value: 3,
          name: 'Both'
        },
      ]
    },
  },
  watch: {
    selectedFromDate(selectedFromDate) {
      this.getTechnicianAppointmentsParams.where_has_appointment_date[0] = selectedFromDate;
    },
    selectedToDate(selectedToDate) {
      this.getTechnicianAppointmentsParams.where_has_appointment_date[1] = selectedToDate;

    },
    selectedTechnician(selectedTechnician) {
      this.getTechnicianAppointmentsParams.technician_id = selectedTechnician.value;
    },
  },
  methods: {
    ...mapActions({
      getTechnicians: 'appTechnicians/getTechnicians',
      getTechnician: 'appTechnicians/getTechnician',
      resetTechnicians: 'appTechnicians/resetTechnicians',
      resetTechnician: 'appTechnicians/resetTechnician',

      getTechnicianAppointments: 'appTechnicianAppointments/getTechnicianAppointments',
      resetTechnicianAppointments: 'appTechnicianAppointments/resetTechnicianAppointments',

      postTechnicianCommissionPayment: 'appTechnicianCommissionPayments/postTechnicianCommissionPayment',
    }),

    async getTechnicianList() {
      await this.getTechnicians(this.getTechniciansParams);
    },

    async getTechnicianAppointmentList() {
      await this.loader(true);
      await this.getTechnicianAppointments(this.getTechnicianAppointmentsParams).then(async (response) => {
        await this.loader(false);
        if (response.status !== 200) {
          await this.showToastMessage(response);
          return;
        }
        await this.getSelectedTechnicianDetails();
        await this.generateTechnicianAppointmentPaymentsData();

        if (!this.technicianAppointmentListPayments.length) {
          this.noTechnicianAppointmentListMessage = "Sorry, no details found by the query!";
        }
      });
    },

    async getSelectedTechnicianDetails() {
      let paramObj = {
        id: this.selectedTechnician.value,
        params: this.getTechnicianParams,
      };
      await this.getTechnician(paramObj).then(async response => {
        if (response.status !== 200) {
          await this.showToastMessage(response);
        }
      });
    },

    async generateTechnicianAppointmentPaymentsData() {

      this.selectedTechnicianDetails = {
        id: this.technician?.id,
        productCommissionType: this.technician?.product_commission_type,
        productCommissionRate: this.technician?.product_commission_rate,
        user: {
          fullName: this.technician?.user?.full_name,
          email: this.technician?.user?.email,
          phoneNumber: this.technician?.user?.phone_number,
        },
        employee: {
          type: this.technician?.employee?.type,
          idNumber: this.technician?.employee?.id_number,
          designation: this.technician?.employee?.designation,
          salaryType: this.technician?.employee?.salary_type,
          salaryAmount: this.technician?.employee?.salary_amount,
        },
      };

      this.technicianAppointmentListPayments = this.technicianAppointments.map(technicianAppointment => {
        let appointmentId = technicianAppointment?.appointment?.id;
        let appointmentReference = technicianAppointment?.appointment?.reference;
        let appointmentType = technicianAppointment?.appointment?.type;
        let appointmentStatus = technicianAppointment?.appointment?.status;
        let appointmentPreference = technicianAppointment?.appointment?.preference;
        let appointmentDate = technicianAppointment?.appointment?.date;
        let appointmentTime = technicianAppointment?.appointment?.time;
        let appointmentLength = technicianAppointment?.appointment?.length;
        let appointmentUnitPrice = technicianAppointment?.appointment?.unit_price;
        let appointmentQuantity = technicianAppointment?.appointment?.quantity;

        let appointmentServiceName = technicianAppointment?.appointment?.service?.name;

        let appointmentCustomerUserFullName = technicianAppointment?.appointment?.customer?.user?.full_name;

        let appointmentChargesServicePrice = technicianAppointment?.appointment?.appointmentCharges.filter(({type}) => type === 'Service Price').map((charge) => {
          return charge.amount ?? 0;
        })[0]
        let appointmentChargesTotalPriceAmount = this.AppointmentTotalChargeAmountCalculation(technicianAppointment?.appointment?.appointmentCharges);
        let technicianWageAmountFromAppointment = this.technicianEmployeeWageAmountCalculationForAppointment(this.technician?.employee, appointmentLength, appointmentUnitPrice);

        let appointmentHasProcessedOrder = false;
        let taggedOrder = technicianAppointment?.appointment?.order;
        let taggedOrderId = taggedOrder?.id;
        let taggedOrderNumber = taggedOrder?.order_no;
        let taggedOrderStatus = taggedOrder?.status;
        let taggedOrderProductItems = [];
        let orderProductCommissionAmount = 0;

        // if appointment is tag in order & order status is not in Pending, Cancelled, Credited and Refund, then generate product items with product type & sell price
        if (taggedOrder !== null && this.isActionableOrder(taggedOrder)) {
          appointmentHasProcessedOrder = true;
          taggedOrderProductItems = taggedOrder?.orderProductItems.map((productItem) => {
            let productType = productItem?.productItem?.product?.type;
            return {
              productItem: productItem?.productItem ?? {},
              productItemType: productType,
              productItemPurchaseCost: productItem?.purchase_cost ?? 0,
              productItemSellPriceAmount: productItem?.productItemTransactions?.sell_price ?? 0,
              productCommission: productItem?.productItemTransactions?.sell_price ?? 0 - productItem?.purchase_cost ?? 0,
            }
          }) ?? [];

          orderProductCommissionAmount = this.orderProductCommissionCalculation(taggedOrderProductItems, this.technician?.product_commission_type, this.technician?.product_commission_rate);
        }

        return {
          id: appointmentId,
          reference: appointmentReference,
          type: appointmentType,
          status: appointmentStatus,
          preference: appointmentPreference,
          date: appointmentDate,
          time: appointmentTime,
          length: appointmentLength,
          unitPrice: appointmentUnitPrice,
          quantity: appointmentQuantity,
          serviceName: appointmentServiceName,
          servicePrice: appointmentChargesServicePrice,
          totalPriceAmount: appointmentChargesTotalPriceAmount,
          technicianWageAmountFromAppointment: technicianWageAmountFromAppointment,
          appointmentHasProcessedOrder: appointmentHasProcessedOrder,
          taggedOrder: {
            id: taggedOrderId,
            orderNo: taggedOrderNumber,
            status: taggedOrderStatus,
          },
          customer: {
            userFullName: appointmentCustomerUserFullName,
          },
          taggedOrderProductItems: taggedOrderProductItems,
          orderProductCommissionAmount: orderProductCommissionAmount,
          totalJobValue: technicianWageAmountFromAppointment * 1 + orderProductCommissionAmount
        };
      });

      this.totalTechnicianWageAmountFromAppointment = this.technicianAppointmentListPayments.reduce((accumulator, currentValue) => {
        accumulator += currentValue?.technicianWageAmountFromAppointment ?? 0;
        return accumulator;
      }, 0);

      this.totalOrderProductCommissionAmount = this.technicianAppointmentListPayments.reduce((accumulator, currentValue) => {
        accumulator += currentValue?.orderProductCommissionAmount ?? 0;
        return accumulator;
      }, 0);

      this.totalTechnicianCommissionPayableAmount = this.technicianAppointmentListPayments.reduce((accumulator, currentValue) => {
        accumulator += currentValue?.totalJobValue ?? 0;
        return accumulator;
      }, 0);
      this.totalServiceHour = this.technicianAppointmentListPayments.reduce((accumulator, currentValue) => {

        let splitAppointmentLength = currentValue.length?.split(':') ?? [];

        let appointmentLengthInMinutes = splitAppointmentLength[0] * 60 + splitAppointmentLength[1] * 1;

        let technicianSpendedTime =  appointmentLengthInMinutes / 60;

        return accumulator + technicianSpendedTime;
      }, 0);

      return {
        technicianDetails: this.selectedTechnicianDetails,
        technicianAppointmentListPayments: this.technicianAppointmentListPayments,
        totalTechnicianWageAmountFromAppointment: this.totalTechnicianWageAmountFromAppointment,
        totalOrderProductCommissionAmount: this.totalOrderProductCommissionAmount,
        totalTechnicianCommissionPayableAmount: this.totalTechnicianCommissionPayableAmount,
        totalServiceHour: this.totalServiceHour,
      }
    },

    isActionableOrder(order = {}) {
      let actionableOrder = false;
      switch (order.status) {
        case 4:
        case "Delivered":
          actionableOrder = true;
          break;
        case 5:
        case "Closed":
          actionableOrder = true;
          break;
        default:
          actionableOrder = false;
          break;
      }
      return actionableOrder;
    },

    AppointmentTotalChargeAmountCalculation(singleAppointmentAppointmentCharges) {
      const decreaseType = ['Discount']
      return singleAppointmentAppointmentCharges.reduce(function (accumulator, currentValue) {
        accumulator = (decreaseType.includes(currentValue.type))
            ? accumulator - currentValue.amount
            : accumulator + currentValue.amount
        return accumulator;
      }, 0);
    },

    technicianEmployeeWageAmountCalculationForAppointment(technicianEmployeeObj, appointmentLength, appointmentUnitPrice) {
      let employeeSalaryType = technicianEmployeeObj?.salary_type;
      let employeeSalaryAmount = (technicianEmployeeObj?.salary_amount/100).toFixed(2)??0;

      let splitAppointmentLength = appointmentLength.split(':');

      let appointmentLengthInMinutes = splitAppointmentLength[0] * 60 + splitAppointmentLength[1] * 1;

      let technicianSpendedTime = appointmentLengthInMinutes / 60;

      if (employeeSalaryType === 'Hourly' || employeeSalaryType === 1) {
        return (appointmentUnitPrice * employeeSalaryAmount / 100) * technicianSpendedTime;
      }

      return 0;
    },

    orderProductCommissionCalculation(productArray, commissionType, commissionRate) {
      return productArray.reduce(function (accumulator, currentValue) {
        let profit = currentValue.productItemSellPriceAmount - currentValue.productItemPurchaseCost;

        if (commissionType === 'Both' || commissionType === currentValue.productItemType) {
          return accumulator + (profit * commissionRate) / 100;
        } else {
          return 0
        }
      }, 0);
    },

    dateFormatter(dateString) {
      // create a new Date object using the input date string
      const date = new Date(dateString);

      // create an array of month names
      const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
      ];

      // get the day, month, and year from the Date object
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      // add a leading zero to the day if it's a single digit
      const formattedDay = day < 10 ? `0${day}` : day;

      // format the date string as "dd Month yyyy" and return
      return `${formattedDay} ${monthNames[monthIndex]} ${year}`;
    },

    async generatePaymentButtonHandler() {
      await this.generateTechnicianCommissionPayment()
    },

    async generateTechnicianCommissionPayment() {
      await this.loader(true);
      let data = {
        technician_id: this.selectedTechnicianDetails?.id,
        from_date: this.selectedFromDate,
        to_date: this.selectedToDate,
        salary_type: this.salaryTypeOptions.find(({name}) => name.toLowerCase() === this.selectedTechnicianDetails?.employee?.salaryType?.trim()?.toLowerCase())?.value ?? null,
        hours_rate: this.selectedTechnicianDetails?.employee?.salaryAmount,
        product_commission_type: this.commissionTypeOptions.find(({name}) => name.toLowerCase() === this.selectedTechnicianDetails?.productCommissionType?.toLowerCase())?.value ?? null,
        product_commission_amount: this.selectedTechnicianDetails?.productCommissionRate,
        details: JSON.stringify({
          technicianDetails: this.selectedTechnicianDetails,
          technicianAppointmentListPayments: this.technicianAppointmentListPayments,
          totalTechnicianWageAmountFromAppointment: this.totalTechnicianWageAmountFromAppointment,
          totalOrderProductCommissionAmount: this.totalOrderProductCommissionAmount,
          totalServiceHour: this.totalServiceHour,
        }),
      };

      await this.postTechnicianCommissionPayment(data).then(async response => {
        this.loader(false);
        if (response.status === 200 || response.status === 201) {
          const toastObj = {
            message: 'Payment Generated.',
            type: 'success'
          };

          await this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });
        }

        await this.showToastMessage(response);

      });
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getTechnicianList();
    await this.loader(false);
  },
  beforeMount() {
    this.resetTechnicians();
    this.resetTechnician();
    this.resetTechnicianAppointments();
  }

}
</script>

<style scoped>

</style>